import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoClose } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtpInput from 'react-otp-input';
import "./login.css";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      phone:null,
      loginModal: true,
      validateEmail: false,
      validatePassword: false,
      validatePhone: false,
      validateLogin: true,
      sidebar: false,
      errors: {},
      confirmLogoutModal: false,
      forgetPasswordModal: false,
      emailForgetPasswordView: true,
      otpForgetPasswordView: false,
      confirmPasswordView: false,
      otpFP: null,
      optID: null
    };
    this.confirmLogoutModal = this.confirmLogoutModal.bind(this);
    this.forgetPasswordModal = this.forgetPasswordModal.bind(this);
    this.loginCheck = this.loginCheck.bind(this);
    this.login = this.login.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.toastFunct = this.toastFunct.bind(this);
    this.successToast = this.successToast.bind(this);
    this.OTPsuccessToast = this.OTPsuccessToast.bind(this);
    this.OTPVerifiedToast = this.OTPVerifiedToast.bind(this);
    this.errorToast = this.errorToast.bind(this);
    this.OTPErrorToast = this.OTPErrorToast.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.clearAsyncStorage = this.clearAsyncStorage.bind(this);
  }
  handleOTPFPChange = (otp) => {
    this.setState({ otpFP:  otp});
  }
  handleEmail(event) {
    this.setState({
      email: event.target.value,
      validateEmail: false,
    });
    this.loginCheck();
  }
  clearAsyncStorage = async() => {
    localStorage.clear();
    window.location.reload();
  }
  confirmLogoutModal = () => {
    this.clearAsyncStorage();
    this.setState({confirmLogoutModal: false})
  }
  forgetPasswordModal = () => {
    this.setState({forgetPasswordModal: !this.state.forgetPasswordModal})
  }
  toggleButton = () => {
    this.setState({
      sideBar: !this.state.sideBar,
    });
  };
  handlePassword(event) {
    this.setState({
      password: event.target.value,
      validatePassword: false,
    });
    this.loginCheck();
  }
  forgotPassword = async () => {
    this.toastFunct();
      let passwordDetails = {
        emailId: this.state.email,
      };
      let ld = JSON.stringify(passwordDetails);
      await fetch("https://backend.myplotpic.com/api/forgotPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: ld,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.message === "Authentication error.") {
            this.errorToast();
          }
          else if(responseJson.error === "unable to send the mail"){
            this.OTPErrorToast();
          }
           else {
            this.OTPsuccessToast();
            this.setState({otpForgetPasswordView: true, emailForgetPasswordView: false, optID: responseJson.data.id._id})
          }
      });
  }
  forgetPasswordOTPVerify = async () => {
    let passwordDetails = {
      id: this.state.optID,
      otp: this.state.otpFP
    };
    let ld = JSON.stringify(passwordDetails);
    console.log(ld)
    await fetch("https://backend.myplotpic.com/api/checkForgotPasswordOTP", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: ld,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.message === "OTP is correct. now, you can change the password") {
            this.OTPVerifiedToast();
            this.setState({otpForgetPasswordView: false, emailForgetPasswordView: false, confirmPasswordView: true})
          }
           else {
            this.OTPErrorToast()
          }
      });
  }
  resetPasswordVerify = async () => {
    let passwordDetails = {
      id: this.state.optID,
      password: this.state.password
    };
    let ld = JSON.stringify(passwordDetails);
    console.log(ld)
    await fetch("https://backend.myplotpic.com/api/resetPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: ld,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.message === "password changed sucessfully") {
            this.passwordResetToast();
            this.setState({otpForgetPasswordView: false, emailForgetPasswordView: false, confirmPasswordView: false, loginModal: true, forgetPasswordModal: false})
          }
           else {
            this.errorToast()
          }
      });
  }
  handlePhone(event) {
    this.setState({ phone: event.target.value });
    let errors = {};
    if (this.state.phone !== null && event.target.value.length === 10) {
      this.setState({ errors: errors});
    } else if (this.state.phone === null) {
      errors["phone"] = "Cannot be empty ";
      this.setState({ errors: errors, isCheckValided: false });
    } else {
      errors["phone"] = "Must be 10 digits";
      this.setState({ errors: errors, isCheckValided: false });
    }
  }
  toastFunct() {
    toast.info("Loading ..", {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("Authenticated", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  OTPsuccessToast() {
    toast.success("OTP Sent", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  OTPVerifiedToast() {
    toast.success("OTP Verified", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  passwordResetToast() {
    toast.success("Password Changed", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  errorToast() {
    toast.error("Authentication Error", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  OTPErrorToast() {
    toast.error("Error: Unable to send OTP", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  loginCheck() {
    if (this.state.email == null) {
      this.setState({
        validateEmail: true,
      });
    }
    if (this.state.password == null) {
      this.setState({
        validatePassword: true,
      });
    } else {
      this.setState({
        validateLogin: false,
      });
    }
  }
  login = async () => {
    if (this.state.validateLogin === false) {
      this.toastFunct();
      let loginDetails = {
        email: this.state.email,
        password: this.state.password,
        contactno: this.state.phone
      };
      let ld = JSON.stringify(loginDetails);
      console.log("Login Details ====> " + ld);
      await fetch("https://backend.myplotpic.com/api/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: ld,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.message === "Authentication error.") {
            this.errorToast();
          }
          else if(responseJson.message === "Account does not exist"){
            this.errorToast();
          }
           else {
            this.successToast();
            this.storeData(responseJson.user);
            this.storeToken(responseJson.token);
            this.setState({ loginModal: false });
          }
        });
    }
  };
  storeData = async (userData) => {
    // const { userData } = this.state;
    localStorage.setItem("userData", JSON.stringify(userData));
    console.log("<=====UserData Saved=====>");
  };
  storeToken = async (token) => {
    localStorage.setItem("token", JSON.stringify(token));
    console.log("<=====Token Saved=====>");
  };
  render() {
    return (
      <div className="login-container">
        <Modal
          show={this.state.loginModal}
          backdrop="static"
          keyboard={false}
          dialogClassName="border-radius-2"
        >
          <ModalBody>
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <IoClose
                size={25}
                className="closeIcon"
                onClick={() => this.props.history.goBack()}
              />
              <center>
                <p
                  className="loginModalTxt"
                  style={{ color: "#ba7105", textAlign: "center" }}
                >
                  Login
                </p>
              </center>
              <p className="ct-frm-lbl">Phone :</p>
              <input
                className="in-cus-ct input_"
                placeholder="Phone"
                type="number"
                onChange={this.handlePhone}
              />
              {this.state.errors["phone"] ? (
                <p id="marginInputs" className="loginErrorTxt">
                  {this.state.errors["phone"]}
                </p>
              ) : (
                <></>
              )}
              <Row >
                <Col sm={5} xs={5} className="divider-Margin">
                  <hr className="left-hr"/>
                </Col>
                <Col sm={2} xs={2} className="divider-Margin">
                  <center>or</center>
                </Col>
                <Col sm={5} xs={5} className="divider-Margin">
                  <hr className="left-hr" />
                </Col>
              </Row>
              <p className="ct-frm-lbl">Email :</p>
              <input
                className="in-cus-ct input_"
                type="text"
                placeholder="Email"
                inputMode="email"
                enterKeyHint="next"
                onChange={this.handleEmail}
              />
              {this.state.validateEmail ? (
                <p id="marginInputs" className="loginErrorTxt">
                  Invalid Email
                </p>
              ) : (
                <></>
              )}
              <p id="marginInputs" className="ct-frm-lbl">
                Password :
              </p>
              <input
                className="in-cus-ct input_"
                type="password"
                placeholder="Password"
                onChange={this.handlePassword}
              />
              {this.state.validatePassword ? (
                <p id="marginInputs" className="loginErrorTxt">
                  Invalid Password
                </p>
              ) : (
                <></>
              )}
            </div>
            <p
              id="marginInputs"
              style={{ textAlign: "center", marginTop: "4%" }}
              className="forgotPass-hover"
              onClick={() => this.setState({loginModal: false, forgetPasswordModal: true })}
            >
              Forgot Password? <b>Click here</b>
            </p>
            <center>
              <button
                style={{
                  borderRadius: 60,
                  backgroundColor: this.state.validateLogin
                    ? "#ffce85"
                    : "#ba7105",
                  marginBottom: "5%",
                }}
                type="submit"
                onClick={this.login}
              >
                Login
              </button>
            </center>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ModalBody>
        </Modal>

        <Modal
          show={this.state.forgetPasswordModal}
          backdrop="static"
          keyboard={false}
          dialogClassName="border-radius-2"
        >
          <ModalBody>
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <IoClose
                size={25}
                className="closeIcon"
                onClick={() => this.props.history.goBack()}
              />
              <center>
                <p
                  className="loginModalTxt"
                  style={{ color: "#ba7105", textAlign: "center", marginBottom:"10%" }}
                >
                  Forgot Password
                </p>
              </center>
              {
                this.state.emailForgetPasswordView?
                <>
                  <p className="ct-frm-lbl">Email :</p>
                  <input
                    className="in-cus-ct input_"
                    type="text"
                    placeholder="Email"
                    inputMode="email"
                    enterKeyHint="next"
                    onChange={this.handleEmail}
                  />
                  {
                    this.state.validateEmail ? (
                      <p id="marginInputs" className="loginErrorTxt">
                        Invalid Email
                      </p>
                    ) : (
                      <></>
                  )}
                  <center>
                    <button
                      style={{
                        marginBottom: "5%",
                        marginTop:"7%"
                      }}
                      className="requestQuote"
                      type="submit"
                      onClick={this.forgotPassword}
                    >
                      Send OTP
                    </button>
                  </center>
                </>
                :
                <></>
              }
              {
                this.state.otpForgetPasswordView?
                <>
                  <center>
                    <p className="ct-frm-lbl">Enter OTP</p>
                    <OtpInput
                        value={this.state.otpFP}
                        onChange={this.handleOTPFPChange}
                        isInputNum={true}
                        containerStyle="otp-block"
                        inputStyle={{  
                            width: '3.5rem',  
                            height: '3.5rem',  
                            margin: '20px 1rem',  
                            fontSize: '1.5rem',
                            color: "#5B5B5B",
                            borderRadius: 4,  
                            border: '2px solid #ba7105',      
                        }}  
                        numInputs={4}
                        separator={<span>-</span>}
                    />
                    <Row>
                      <Col md={6}>
                        <button
                          style={{
                            marginBottom: "5%",
                            marginTop:"7%"
                          }}
                          className="requestQuote2"
                          type="submit"
                          onClick={this.forgetPasswordOTPVerify}
                        >
                          Submit
                        </button>
                      </Col>
                      <Col>
                        <button
                          style={{
                            marginBottom: "5%",
                            marginTop:"7%"
                          }}
                          className="requestQuote"
                          type="submit"
                          onClick={() => this.setState({otpForgetPasswordView: false, emailForgetPasswordView: true, confirmPasswordView: false})}
                        >
                          Back
                        </button>
                      </Col>
                    </Row>
                    
                  </center>
                </>
                :
                <></>
              }
              {
                this.state.confirmPasswordView ?
                <>
                <center>
                    <p className="ct-frm-lbl">Enter New Password</p>
                    <input
                      className="in-cus-ct input_"
                      type="password"
                      placeholder="Password"
                      onChange={this.handlePassword}
                    />
                    <button
                      style={{
                        marginBottom: "5%",
                        marginTop:"7%"
                      }}
                      className="requestQuote2"
                      type="submit"
                      onClick={this.resetPasswordVerify}
                    >
                      Submit
                    </button>
                </center>
                </>
                :
                <></>
              }
            </div>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ModalBody>
        </Modal>
        <Modal
          show={this.state.confirmLogoutModal}
          backdrop="static"
          keyboard={false}
          dialogClassName="border-radius-2"
          onHide={() => this.setState({ confirmLogoutModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Log Out
            </Modal.Title>
          </Modal.Header>
          <ModalBody>
            <div>
              <center>
                <p className="logoutModal-txt">Are you sure you want to logout?</p>
                <br/>   
                <Row>
                  <Col md={6}>
                    <button
                      className="requestQuote"
                      onClick={this.confirmLogoutModal}
                    >
                      <p>Confirm</p>
                    </button>
                  </Col>
                  <Col md={6}>
                    <button
                      className="requestQuote2"
                      onClick={() => this.setState({ confirmLogoutModal: false })}
                    >
                      <p className="register-modal-btn-txt">Cancel</p>
                    </button>
                  </Col>
                </Row>      
              </center>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Login;
