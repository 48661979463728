import React from "react";
import { withRouter } from "react-router-dom";
import "./home.css";
import Loader from "react-js-loader";
import Footer from "../../components/Footer/footer";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-slideshow-image/dist/styles.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HomePageCard from "../../components/HomePageCard/HomePageCard";
import SearchCard from "../../components/SearchCard/SearchCard";
import { TbHandClick, TbSpeakerphone } from "react-icons/tb";
import { GiHouseKeys } from "react-icons/gi";
import { FiMapPin, FiMail } from "react-icons/fi";
import { BsTelephone } from "react-icons/bs";
import Navbar from "../../components/NavBar/index";
import { Container, Button, Link as FabLink, lightColors} from 'react-floating-action-button';

class home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      adsData: [],
      errors: {},
      adsFilteredData: null,
      adsArrayLength: 85,
      loaderModal: true,
      cityRandom : "Vizag"
    };
    this.handleEmail = this.handleEmail.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.toastFunct = this.toastFunct.bind(this);
    this.successToast = this.successToast.bind(this);
    this.warnToast = this.warnToast.bind(this);
    this.handleAds = this.handleAds.bind(this);
    this.getData = this.getData.bind(this);
    this.sendContact = this.sendContact.bind(this);
    this.navigateToDetails = this.navigateToDetails.bind(this);
  }
  navigateToDetails = (data) => {
    console.log(data);
    this.props.history.push("/details", data);
  }
  handleName(event) {
    let errors = this.state.errors;
    let name = event.target.value.length
    if (name>4) {
      errors["name"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["name"] = "Must be at least 5 characters";
      this.setState({ errors: errors});
    }
    this.setState({
      name: event.target.value,
    });
  }
  handleEmail(event) {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
  }
  handlePhone(object) {
    if (object.target.value.length <= object.target.maxLength) {
      this.setState({ phone: object.target.value });
    }
    let errors = this.state.errors;
    var phoneLength = object.target.value.length;
    if (phoneLength === 11 || phoneLength === 10 ) {
      errors["phone"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["phone"] = "Must be 10 digits";
      this.setState({ errors: errors});
    }
  }
  handleMessage(event) {
    let errors = this.state.errors;
    let message = event.target.value.length
    if (message>14) {
      errors["message"] = null
      this.setState({ errors: errors});
    }
     else {
      errors["message"] = "Must be at least 15 characters";
      this.setState({ errors: errors});
    }
    this.setState({ message: event.target.value });
  }
  toastFunct() {
    toast.info("Sending ..", {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("Inquiry submitted successfully", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  warnToast() {
    toast.warn("Form Data Invalid", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  sendContact = () => {
    
    const {name, email, phone, message, errors} = this.state;
    let emailWarning = this.state.errors["email"];
    let nameWarning = this.state.errors["name"];
    let phoneWarning = this.state.errors["phone"];
    let messageWarning = this.state.errors["message"];
    if(name != "" && email != "" && phone != "" && message != ""){
      if(emailWarning == null && nameWarning == null && phoneWarning == null && messageWarning == null)
      {
        this.toastFunct();
        let passwordDetails = {
          name: this.state.name,
          mail: this.state.email,
          message: this.state.message,
          phone: this.state.phone,
        };
        let ld = JSON.stringify(passwordDetails);
        console.log(ld);
        fetch("https://backend.myplotpic.com/api/addEnquiry", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: ld,
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson);
            this.setState({
              name: "",
              email: "",
              message: "",
              phone: ""
            })
            if (responseJson.message === "Inquiry submitted successfully") {
              this.successToast();
            }
          });
      }
      else{
        this.warnToast();
        console.log("Form Invalid")
      }
    }
    else{
      this.warnToast();
      console.log("Form Invalid")
    }
  };
  handleAds = () => {
    this.props.history.push("/ads");
  };
  getData = () => {
    fetch(`https://backend.myplotpic.com/api/getAdvertisement_exclusive`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          adsData: responseJson,
          adsFilteredData: responseJson,
          adsArrayLength: responseJson.length,
        });
        console.log("ADs Data ===>", responseJson);
        console.log("ADs Length ===>", this.state.adsArrayLength);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  componentDidMount() {
    this.getData();
    setTimeout(() => {
      this.setState({ loaderModal: false });
    }, 3000);
    const script = document.createElement("script");
    script.src = "https://kit.fontawesome.com/31b551d447.js";
    script.async = true;
    document.body.appendChild(script);
    this.getRandomInt(4);
  };
  getRandomInt(max){
    let result = Math.floor(Math.random() * max);
    if(result===0){
      this.setState({
        cityRandom: "Vizag"
      })
        setTimeout(() => {
          this.getRandomInt(4);
      }, 2000);
    }
    else if(result===1){
      this.setState({
        cityRandom: "Visakhapatnam"
      })
        setTimeout(() => {
          this.getRandomInt(4);
      }, 2000);
    }
    else if(result===2){
      this.setState({
        cityRandom: "Chennai"
      })
        setTimeout(() => {
          this.getRandomInt(4);
      }, 2000);
    }
    else{
      this.setState({
        cityRandom: "Hyderabad"
      })
        setTimeout(() => {
          this.getRandomInt(4);
      }, 2000);
    }
  }
  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 3,
        paritialVisibilityGutter: 0,
      },
      tablet: {
        breakpoint: { max: 1199, min: 699 },
        items: 2,
        paritialVisibilityGutter: 0,
      },
      mobile: {
        breakpoint: { max: 699, min: 0 },
        items: 1,
        paritialVisibilityGutter: 0,
      },
    };
    return (
      <div>
        <div className="con-box">
          <div>
            <div>
              <Navbar />
              <SearchCard></SearchCard>
              <div className="flatsin-div">
                <div style={{ paddingTop: "1%" }}>
                  <p
                    className="txt_randomCity"
                  >
                    Flats in {this.state.cityRandom}
                  </p>
                </div>
                {this.state.loaderModal ? (
                  <Loader
                    type="spinner-circle"
                    bgColor={"#02486c"}
                    title={"Loading"}
                    color={"#02486c"}
                    size={80}
                  />
                ) : (
                  <>
                    <Carousel
                      autoPlay={false}
                      autoPlaySpeed={1600}
                      transitionDuration={1200}
                      responsive={responsive}
                    >
                      {
                        this.state.adsData.map((item, i) => (
                          <div onClick={() => this.navigateToDetails(item)}>
                            <HomePageCard key={i} data={item}></HomePageCard>
                          </div>
                        ))
                      }
                    </Carousel>
                    <Row>
                      <Col md={10} xs={3} sm={3}></Col>
                      <Col md={2} xs={8} sm={8}>
                        <button
                          className="Ads-Btn"
                          onClick={() => this.handleAds()}
                        >
                          More
                        </button>
                      </Col>
                      <Col xs={1} sm={1}></Col>
                    </Row>
                    
                  </>
                )}
              </div>
              <div className="flatsin-div">
              <Container className="fab-btn-hvr">
                <FabLink href="#"
                  tooltip="Virtual Tour"
                  styles={{backgroundColor: "#00476b", color: lightColors.white}}
                  icon="fas fa-vr-cardboard" />
                  <FabLink href="#"
                    tooltip="Brochure"
                    styles={{backgroundColor: "#00476b", color: lightColors.white}}
                    icon="fa fa-file-image-o fa-lg" />
                  <FabLink href="https://wa.me/917249556789?text=Hello"
                    tooltip="Whatsapp Link"
                    styles={{backgroundColor: "#00476b", color: lightColors.white}}
                    icon="fab fa-whatsapp fa-lg" />
                <Button
                    icon="fa fa-plus fa-lg"
                    iconStyles={{padding:45, size: 55}}
                    rotate={true}
                    styles={{backgroundColor: "#00476b", color: lightColors.white,}}
                />
              </Container>
                <Row>
                  <Col md={6} xs={12} sm={12}>
                    <div className="text-pop">
                      <p className="txt_Title">Visit Your Site Virtually.</p>
                      <p className="txt_subtitle">
                        We help to monitor your flat when you are away and
                        <br />
                        unable to reach.
                      </p>
                      <div>
                        <Link to="/create-order">
                          <button className="visit-site-blue">Book Now</button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    xs={12}
                    sm={12}
                    style={{ marginTop: "-3%" }}
                    className="youtube_homepage"
                  >
                    <Row>
                      <iframe
                        style={{ marginTop: "23%" }}
                        src="https://www.youtube.com/embed/jTeKK4RGddk"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        height="320"
                        width="800"
                      ></iframe>
                    </Row>
                  </Col>
                  <Col
                    md={6}
                    lg={6}
                    xs={12}
                    sm={12}
                    style={{ marginTop: "-3%" }}
                    className="youtube_homepage_mobile"
                  >
                    <Row>
                      <iframe
                        style={{ marginTop: "10%", marginBottom: "5%" }}
                        src="https://www.youtube.com/embed/jTeKK4RGddk"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        height="175"
                        width="600"
                      ></iframe>
                    </Row>
                  </Col>
                </Row>
              </div>
              <br/>
              <br/>
            </div>
          </div>
        </div>
        <div className="flatsin-div">
          <p className="title-home-about-services">Our Services</p>
          <div className="desktopView_home">
            <Row>
              <Col md={12} xs={12} sm={12}>
                <div>
                  <Row>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="mnu-ctsrvv one">
                        <div className="mnu-ctsrvvv-service">
                          <center>
                            <div className="inner-bluecircle-services">
                              <AiOutlineHome
                                size={33}
                                className="services-card-icons"
                              />
                            </div>
                          </center>
                          <p className="title-ct-srvv">
                            Apartment Progress
                          </p>
                          <p className="txt-ct-srvv-service">
                            Want to know the status work of construction or
                            interior work of your flat? We are here to help
                            you.
                          </p>
                          <p className="bk-nwbtn-service">
                            <Link
                              className="links_text_white"
                              to="/services"
                            >
                              Explore
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="mnu-ctsrvv one">
                        <div className="mnu-ctsrvvv-service">
                          <center>
                            <div className="inner-bluecircle-services">
                              <TbHandClick
                                size={33}
                                className="services-card-icons"
                              />
                            </div>
                          </center>
                          <p className="title-ct-srvv">
                            Virtual Visit to Plot/Flat
                          </p>
                          <p className="txt-ct-srvv-service">
                            Are you able to monitor or visit your Flat/Plot
                            due to various reasons. We are here to help you.
                          </p>
                          <p className="bk-nwbtn-service">
                            {" "}
                            <Link
                              className="links_text_white"
                              to="/services"
                            >
                              Explore
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="mnu-ctsrvv one">
                        <div className="mnu-ctsrvvv-service">
                          <center>
                            <div className="inner-bluecircle-services">
                              <GiHouseKeys
                                size={33}
                                className="services-card-icons"
                              />
                            </div>
                          </center>
                          <p className="title-ct-srvv">
                            Rental Property Visit
                          </p>
                          <p className="txt-ct-srvv-service">
                            Want to know the status work of construction or
                            interior work of your flat? We are here to help
                            you.
                          </p>
                          <p className="bk-nwbtn-service">
                            <Link
                              className="links_text_white"
                              to="/services"
                            >
                              Explore
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="mnu-ctsrvv four">
                        <div className="mnu-ctsrvvv-service">
                          <center>
                            <div className="inner-bluecircle-services">
                              <TbSpeakerphone
                                size={33}
                                className="services-card-icons"
                              />
                            </div>
                          </center>
                          <p className="title-ct-srvv">Advertisements</p>
                          <p className="txt-ct-srvv-service">
                            Are you unable to monitor or Visit your Plot or
                            flat due to various reasons? We are here to help
                            you.
                          </p>
                          <p className="bk-nwbtn-service">
                            <Link
                              className="links_text_white"
                              to="/ads"
                            >
                              Explore
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div>
          <div className="contact-contacts-new">
            <center>
              <p className="title-home-about">Get in touch!</p>
              <Row className="icons-new">
                <Col md={4} xs={4} sm={4}>
                  <FiMapPin size={26} className="ads-call-icons-new " />
                  <p className="home-txt-content-new">Madhurawada</p>
                </Col>
                <Col md={4} xs={4} sm={4}>
                  <BsTelephone size={26} className="ads-call-icons-new" />
                  <p className="home-txt-content-new">7249556789</p>
                </Col>
                <Col md={4} xs={4} sm={4}>
                  <FiMail size={26} className="ads-call-icons-new-email">
                    {" "}
                  </FiMail>
                  <p className="home-txt-content-new">sample@hi.com</p>
                </Col>
              </Row>
            </center>
            <form>
              <div className="form-container-contact">
                <Row>
                  <Col md={12} xs={12} sm={12}>
                    <p className="ct-frm-lbl">Name</p>
                    <input
                      className="input-new-contact"
                      type="text"
                      value={this.state.name}
                      placeholder="Name"
                      onChange={this.handleName}
                      onFocus={this.handleName}
                    />
                    {
                      this.state.errors["name"] ? (
                        <p id="marginInputs" className="loginErrorTxt">
                          {this.state.errors["name"]}
                        </p>
                      ) : (
                        <></>
                      )
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md={6} xs={12} sm={12} className="mt-2">
                    <p className="ct-frm-lbl">Email</p>
                    <input
                      className="input-new-contact"
                      type="text"
                      value={this.state.email}
                      placeholder="Email"
                      onChange={this.handleEmail}
                      onFocus={this.handleEmail}
                    />
                    {
                      this.state.errors["email"] ? (
                        <p id="marginInputs" className="loginErrorTxt">
                          {this.state.errors["email"]}
                        </p>
                      ) : (
                        <></>
                      )
                    }
                  </Col>
                  <Col md={6} xs={12} sm={12} className="mt-2">
                    <p className="ct-frm-lbl">Phone Number</p>
                    <input
                      className="input-new-contact"
                      placeholder="Phone Number"
                      onChange={this.handlePhone}
                      onFocus={this.handlePhone}
                      value={this.state.phone}
                      type = "number" maxLength = "10"
                    />
                    {
                      this.state.errors["phone"] ? (
                        <p id="marginInputs" className="loginErrorTxt">
                          {this.state.errors["phone"]}
                        </p>
                      ) : (
                        <></>
                      )
                    }
                  </Col>
                </Row>
                <Row>
                  <Col md={12} xs={12} sm={12} className="mt-2">
                    <p className="ct-frm-lbl">Message</p>
                    <textarea
                      className="input-new-contact-message"
                      type="text"
                      placeholder="Message"
                      rows="4"
                      value={this.state.message}
                      onFocus={this.handleMessage}
                      onChange={this.handleMessage}
                    />
                    {
                      this.state.errors["message"] ? (
                        <p id="marginInputs" className="loginErrorTxt">
                          {this.state.errors["message"]}
                        </p>
                      ) : (
                        <></>
                      )
                    }
                  </Col>
                </Row>
              </div>
            </form>
            <center>
              <button
                style={{ borderRadius: 60 }}
                className="sub-btn contactbtn"
                onClick={this.sendContact}
              >
                Submit
              </button>
            </center>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(home);
