import React from "react";
import { withRouter } from "react-router-dom";
import "./notifications.css";
import Footer from "../../components/Footer/footer";
import axios from "axios";
import { AiOutlineBell } from "react-icons/ai";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "../../components/NavBar/index";
import no_notif from "../../assets/404error.png";

class notifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationData: [],
            data : {}
        };
        this.getData = this.getData.bind(this);
      }
      getData = () => {
        var token = window.localStorage.getItem("token");
        var stripToken = token.replaceAll('"', '');
        console.log("token ==>", stripToken)
        var userData = JSON.parse(window.localStorage.getItem("userData"));
        var id = userData._id;
        var api =" https://backend.myplotpic.com/api/" + id + "/myAppNotification"
        axios.get(api, { headers: { "Authorization": `Bearer ${stripToken}` } })
          .then(res => {
            this.setState({
                notificationData: res.data
            })
            console.log("RESPONSE DATA SAVED ======>", this.state.notificationData);
        }).catch((error) => {
             console.error(error);
        });
      }
      componentDidMount = async () => {
        this.getData();
      };
  render() {
    return (
    <div>
      <Navbar/>
      <div className="container-status notif-margin">
        <div className="new-bx-ads status-search">
            <p className="text_Title-notif">Recent Notifications</p>
        </div>
        {
          this.state.notificationData === [] ?
          <>
            {
                this.state.notificationData.map((item, index) => (
                    <div key={index} className="notification-div">
                      <Row>
                        <Col md={1} xs={1} sm={1}>
                          <AiOutlineBell size={22} className="notif-active" />
                        </Col>
                        <Col md={11} xs={11} sm={11}>
                        <p className="notification-txt">{item.message}</p>
                        </Col>
                      </Row>
                    </div>
                ))
            }
          </>
          :
          <center>
            <img src={no_notif} style={{ height: 400, width: 400 }}/>
          </center>
        }
        
      </div>
      <Footer/>
    </div>
    );
  }
}

export default withRouter(notifications);
