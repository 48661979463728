import React from "react";
import { withRouter } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./verify-email.css";
import registerImg from "../../assets/registerImg.png";
import Footer from "../../components/Footer/footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OtpInput from 'react-otp-input';
import Navbar from "../../components/NavBar/index";

class verify_email extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otpView: true,
      otp: '',
      isOTPEntered: false,
    };
    this.toastFunct = this.toastFunct.bind(this);
    this.resendOTPFunct = this.resendOTPFunct.bind(this);
    this.successToast = this.successToast.bind(this);
    this.errorToast = this.errorToast.bind(this);
  }
  componentDidMount = async () => {
    var userData = localStorage.getItem('userData');
    var userDataParse = JSON.parse(userData);
    console.log(userDataParse);
    let send = {
      id: userDataParse._id,
      emailId: userDataParse.email,
    };
    const token = localStorage.getItem("token");
    console.log("Token ===>", JSON.parse(token))
    let ld = JSON.stringify(send);
    console.log("API send ==>", ld)
    await fetch(
    "https://backend.myplotpic.com/api/sendOTPEmail",
    {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + JSON.parse(token),
        },
        body: ld,
    }
    )
    .then((response) => response.json())
    .then((responseJson) => {
        console.log(responseJson);
        this.resendOTPFunct();
        
    });
  };
  toastFunct() {
    toast.info("Loading ..", {
      position: "bottom-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  resendOTPFunct() {
    toast.success("OTP Sent", {
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("OTP Verified", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  errorToast() {
    toast.error("Incorrect OTP, Try Again", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  handleChange = (otp) => {
    this.setState({ otp });
    var otp = this.state.otp;
    if(otp.length==3)
    {
      this.setState({ isOTPEntered: true });
    }
    else{
      this.setState({ isOTPEntered: false });
    }
  }
  
  // SIGNUP API CALL
  verifyEmailAPI = async () => {
    this.toastFunct();
    var userData = localStorage.getItem('userData');
    var userDataParse = JSON.parse(userData);
    console.log(userDataParse._id);
    let send = {
      id: userDataParse._id,
      otp: this.state.otp,
    };
    let ld = JSON.stringify(send);
    console.log(ld);
    const token = localStorage.getItem("token");
    console.log("Token ===>", JSON.parse(token))

    await fetch(
      "https://backend.myplotpic.com/api/verifyContect",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + JSON.parse(token),
        },
        body: ld,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.message==="OTP is incorrect") {
          this.errorToast();
        }
        else {
          this.successToast();
          this.props.history.push("/verify-phone")
        }
      });
  }
  storeData = async (userData) => {
    // const { userData } = this.state;
    localStorage.setItem("userData", JSON.stringify(userData));
    console.log("<=====UserData Saved=====>");
  };
  render() {
    return (
    <div>
      <Navbar />
      <div className="registerContainer">
        <Row>
            <Col md={6}>
                <div className="secondDiv_verify">
                  <p className="almostThere_txt">Verify Email</p>
                  <p className="subRegisterTitle">Kindly check your registered email for One-Time-Password</p>
                  <center>
                    <OtpInput
                        value={this.state.otp}
                        onChange={this.handleChange}
                        isInputNum={true}
                        containerStyle="otp-block"
                        inputStyle={{  
                            width: '3.5rem',  
                            height: '3.5rem',  
                            margin: '20px 1rem',  
                            fontSize: '1.5rem',
                            color: "#4da6ff",
                            borderRadius: 4,  
                            border: '2px solid #4da6ff',      
                        }}  
                        numInputs={4}
                        separator={<span>-</span>}
                    />
                    <button
                        style={{
                          borderRadius: 5,
                          backgroundColor: "#fff",
                          marginTop:"5%",
                          marginRight:"4%",
                          color:"#02486c"
                        }}
                        className="resend-btn"
                        onClick={this.componentDidMount}
                    >
                        Resend
                    </button>
                    {
                        this.state.isOTPEntered?
                        <button
                            style={{
                            borderRadius: 5,
                            backgroundColor: "#02486c",
                            marginTop:"5%"
                            }}
                            onClick={this.verifyEmailAPI}
                        >
                            Verify
                        </button>
                        :
                        <button
                            disabled
                            style={{
                            borderRadius: 5,
                            backgroundColor: "#4da6ff",
                            marginTop:"5%"
                            }}
                        >
                            Verify
                        </button>
                    }
                  </center>
                </div>
            </Col>
            <Col md={6} className="firstDiv">
              <center>
                  <img
                      alt="underline"
                      src={registerImg}
                      className="firstDiv-img"
                  />
                </center>
            </Col>
        </Row>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(verify_email);
