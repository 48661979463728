import React, { Component, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './Status.css';
import axios from "axios";
import 'font-awesome/css/font-awesome.min.css';
import { IoLocationOutline, IoCalendarSharp } from "react-icons/io5";
import { BsFillCircleFill } from "react-icons/bs";
import { GiHouse } from "react-icons/gi";
import Footer from "../../components/Footer/footer";

class  Status extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      orderData: [],
      loaderModal: true , 
      data : {}
     
    };
    this.getData = this.getData.bind(this);
    this.handleClick = this.handleClick.bind(this);
  } 
  handleClick(item) {
    console.log("DATA Passed on Update ====>", item); 
    window.localStorage.setItem("orderUpdate", JSON.stringify(item)); 
    this.props.history.push("/update")
  }
  getData = () => {
    var token = window.localStorage.getItem("token");
    var stripToken = token.replaceAll('"', '');
    var userData = JSON.parse(window.localStorage.getItem("userData"));
    var id = userData._id;
    console.log(token);
    console.log(stripToken);
    var api =" https://backend.myplotpic.com/api/" + id + "/orders"
    axios.get(api, { headers: { "Authorization": `Bearer ${stripToken}` } })
      .then(res => {
        console.log(res.data);
        this.setState({
          orderData: res.data
        })
    }).catch((error) => {
            console.error(error);
    });
  }
  componentDidMount = async () => {
    this.getData();
    setTimeout( () => {
      this.setState({loaderModal: false})
    }, 3000);
  };
checknull(value)
{
  if(value == null)
  {
    return "Not Filled"
  }
  else
  {
    return value;
  }
}
convertdate(date)
{
  return date.slice(0,10); 
}
render() { 
  return (
    <div>
      <div className="container-status">
        <div className="new-bx-ads status-search">
          <Row>
            <Col md={8} sm={7} xs={7}>
              <p className="text_Title-ads">Orders Status</p>
            </Col>
            <Col md={4} sm={5} xs={5}>
              <input
                  className="ads-searchBar"
                  type="text"
                  placeholder="Search Order ID"
                  onChange={this.handleSearch}
                  onBlur={this.onBlurSearch}
              ></input>
            </Col>
          </Row>
        </div>
        {
        this.state.orderData.map((item,index) => (
          <div className="cards-status" key={index}>
            <div className="cards-status-inner-active">
                <p className="title-content"><BsFillCircleFill size={14} className="nextIconX-active" />{item.status}</p>
            </div>
            <Row>
              <Col md={4} sm={12} xs={12}>
                <div className="cards-status-inner">
                  <p className="subtitle-content">Order ID:</p>
                  <p className="title-content">{item.orderNo}</p>
                </div>
                <div className="cards-status-inner">
                  <p className="subtitle-content">Order Type</p>
                  <p className="title-content ">{item.type}</p>
                </div>
                <div className="cards-status-inner">
                  <p className="subtitle-content">Subscription Amount</p>
                  <p className="title-content ">Rs. {item.priceOrSubscription}</p>
                </div>
                <div className="cards-status-inner">
                  <p className="subtitle-content">Customer Contact Info</p>
                  <p className="title-content">{this.checknull(item.wpContactNo)}</p>
                </div>
                <div className="cards-status-inner">
                  <p className="subtitle-content">Order Date</p>
                  <p className="title-content"><IoCalendarSharp size={22} className="nextIconX" />{this.convertdate(item.createdAt)}</p>
                </div>
              </Col>
              <Col md={4} sm={12} xs={12}>
                <div className="cards-status-inner">
                  <p className="subtitle-content">Service Due</p>
                  <p className="title-content"><IoCalendarSharp size={22} className="nextIconX" />Not filled</p>
                </div>
                <div className="cards-status-inner">
                  <p className="subtitle-content"><IoLocationOutline size={22} className="nextIconX" />Address</p>
                  <p className="title-content">{this.checknull(item.street)}</p>
                  <p className="title-content">{this.checknull(item.area)}, {this.checknull(item.town)}</p>
                  <p className="title-content">{this.checknull(item.pincode)}</p>
                  <p className="title-content">{this.checknull(item.country)}</p>
                </div>
                <div className="cards-status-inner">
                  <p className="subtitle-content"><GiHouse size={22} className="nextIconX" />Property Details</p>
                  <p className="title-content"><span className="property-details-txt">Property Name:</span> {this.checknull(item.propertyPetName)}</p>
                  <p className="title-content"><span className="property-details-txt">Plot No. </span>{this.checknull(item.plotNumber)}</p>
                  <p className="title-content"><span className="property-details-txt">Venture </span>{this.checknull(item.venture)}</p>
                </div>
              </Col>
              <Col md={4} sm={12} xs={12}>
                <img src="https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2021/08/download-23.jpg" alt="card__image" width="100%"/>
                <Row>
                    <Col md={6} sm={6} xs={6}>
                      <button
                        className="requestQuoteRenew"
                      >
                        <p>Renew</p>
                      </button>
                    </Col>
                    <Col md={6} sm={6} xs={6}>
                      <button
                        className="requestQuoteEdit"
                        onClick= {() => this.handleClick(item)}
                      >
                        <p>Edit Order</p>
                      </button>
                    </Col>
                  </Row> 
              </Col>
            </Row>
          </div>    
        ))}
      </div>
      <Footer/>
    </div>
  )}
}
export default Status;