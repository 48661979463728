
import React, { Component } from 'react';
import './Homestyle.css';
import { useState,useEffect } from 'react';
import { useHistory } from 'react-router';
import upload from './Assets/up.jpg';
import previous from './Assets/previous.png';
import jigsaw from './Assets/jigsaw.png';
import landmarkIcon from './Assets/landmark.png';
import furnitures from './Assets/furnitures.png';
import star from './Assets/star.png';
import { FiMapPin } from "react-icons/fi";
import { Slide } from 'react-slideshow-image';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import 'font-awesome/css/font-awesome.min.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useLocation } from "react-router-dom";
import Navbar from "../../components/NavBar/index";
 
function Details(props){
	const location = useLocation();
	const[type,setType]=useState([]);
	const[size,setSize]=useState([]);
	const[price,setPrice]=useState([]);
	const[title,setTitle]=useState([]);
	const[city, setCity]=useState([]);
	const[landmark, setLandmark]=useState([]);
	const[furnish,setFurnish]=useState([]);
	const[amenities, setAmenities]=useState([]);
	const[image,setimage] = useState([]); 
	const[item,setItem]=useState([]);
	const[id,setId]=useState([]);
	const  endpoint = "https://backend.myplotpic.com/uploads/"; 
	const history = useHistory();
	const handleDetails = () => {
		history.push("/ads")
    }
	useEffect(()=>{
	let json = JSON.parse(window.localStorage.getItem("json"));
	const queryParams =  new URLSearchParams(window.location.search);
	const adId = queryParams.get("ad");
	
	if(adId && adId != undefined){
		console.log("Via URL ==>")
		var body = {
			adId: adId
		}
		fetch(`https://backend.myplotpic.com/api/getAdByID`, {
			method: "POST",
			body: new URLSearchParams(body),
		})
			.then((response) => response.json())
			.then((responseJson) => {
				setSize(responseJson.data[0].areaInCity); 
				setType(responseJson.data[0].typeProperty);
				setPrice(responseJson.data[0].startingPrice);
				setTitle(responseJson.data[0].adTitle);
				setCity(responseJson.data[0].city);
				setLandmark(responseJson.data[0].landmark);
				setFurnish(responseJson.data[0].furnish);
				setAmenities(responseJson.data[0].amenities[0]);
				setItem(responseJson.data[0].files); 
				setId(responseJson.data[0].adId);
				console.log(responseJson.data[0])
		})
	}
	else if(location.state){
		console.log("Via Route ==>")
		console.log(location.state)
		setSize(location.state.areaInCity); 
		setType(location.state.typeProperty);
		setPrice(location.state.startingPrice);
		setTitle(location.state.adTitle);
		setCity(location.state.city);
		setLandmark(location.state.landmark);
		setFurnish(location.state.furnish);
		setAmenities(location.state.amenities[0]);
		setItem(location.state.files); 
		setId(location.state.adId);
	}
	else if(json){
		console.log("Via Ads ==>")
		setSize(json.areaInCity); 
		setType(json.typeProperty);
		setPrice(json.startingPrice);
		setTitle(json.adTitle);
		setCity(json.city);
		setLandmark(json.landmark);
		setFurnish(json.furnish);
		setAmenities(json.amenities[0]);
		setItem(json.files); 
		setId(json.adId);
	}
	else {
	}
	},[]);
	useEffect(() => {
		// 👇️ scroll to top on page load
		window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
	  }, []);
	const responsive = {
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 3,
		  paritialVisibilityGutter: 0
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 1,
		  paritialVisibilityGutter: 0
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1,
		  paritialVisibilityGutter: 0
		}
	  };
	return(
		<div>
			<Navbar />
			<div className='container minHeightForHover'>
				<div className='row-item-mobile'>
					<div className='container-details-inner'>
						<div className='back1'>
							<img onClick={handleDetails} src={previous} className="back-icon"></img>
							<p>Ad ID:#{id}</p>
						</div>
					</div>
					<h1 className='text-left style1 title1 mt-4'>{title}</h1> 
					<div className="div1">
						<Row>
							<Col md={1} sm={1} xs={1}>
								<FiMapPin size={24} className="ads-call-icons"/>
							</Col>
							<Col md={9} sm={9} xs={9}>
								<p>{city}</p>
							</Col>
							<Col md={2} sm={2} xs={2}>
								<Row>
									<Col md={6} sm={6} xs={6}>
										<p>4.2</p>
									</Col>
									<Col md={6} sm={6} xs={6}>
										<img src={star} height={18} width={18}></img>
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col md={4} sm={4} xs={4}>
							<div className='box-card'>
								<p color='gsGraphite  p1' className='text-titleX'>Type</p>
								<p color='gsGraphite' className='text-subtitle'>{type}</p>
							</div>
							</Col>
							<Col md={4} sm={4} xs={4}>
							<div className ='box-card'>
								<p color='gsGraphite' className='text-titleX'>Size</p>
								<p color='gsGraphite' className='text-subtitle'>{size}</p>
							</div>
							</Col>
							<Col md={4} sm={4} xs={4}>
							<div className ='box-card'>
								<p color ='gsGraphite' className='text-titleX'>Price</p>
								<p color ='gsGraphite' className='text-subtitle'>{price}</p>
							</div>
							</Col>
						</Row>
						<div className='row r2'>
							<button type="button" className="check1 check2 "> Contact </button>
						</div>
					</div>
				</div>
				<Row>
					<Col sm={12} md={7} lg={7}>
						<div className='container-details-inner'>
							<div className='back1 hideForMobile'>
								<img onClick={handleDetails} src={previous} className="back-icon"></img>
								<p>Ad ID:#{id}</p>
							</div>
							<Slide arrows={false} duration={3200}>
								{item.map((slideImage, index)=> (
									<img className="mb-3 mt-4 img1" alt="slider images" src={endpoint+slideImage} />
									) 
								)} 
							</Slide>
							<div>
								<img src={upload} className="mb-5 mt-5 mt-3 img3"></img>
							</div>
						</div>
						<div className='row ml-3'>
							<h3 className='desc'> Description</h3>
						</div>
						<div className='row text-left'>
							<p className='para1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><br/>
							<p className='para1-readMore'> Read More</p>
						</div>
						<div className='.pic'>
							<iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d4968.258020575214!2d-0.0936361!3d51.4925!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1653981932849!5m2!1sen!2sin" 
							width="100%" height="250" allowFullScreen="{false}" loading="lazy" style={{borderRadius: 15, marginBottom: "7%"}} ></iframe>
						</div>
						<h3 className='loc1'>Features</h3>
						<Row>
							<Col md={4} xs={6} sm={6}>
								<div className='cards-about-team'>
									<div className="card-about-team">
									<div className="card-body-about-team">
										<center><img src={furnitures} className="team-imgs"/></center>
									</div>
									<p className="team-text-title">Furnish</p>
									<p className="team-text-subtitle">{furnish}</p>
									</div>
								</div>
							</Col>
							<Col md={4} xs={6} sm={6}>
								<div className='cards-about-team'>
									<div className="card-about-team">
									<div className="card-body-about-team">
										<center><img src={landmarkIcon} className="team-imgs"/></center>
									</div>
									<p className="team-text-title">Landmark</p>
									<p className="team-text-subtitle">{landmark}</p>
									</div>
								</div>
							</Col>
							<Col md={4} xs={12} sm={12}>
								<div className='cards-about-team'>
									<div className="card-about-team">
									<div className="card-body-about-team">
										<center><img src={jigsaw} className="team-imgs"/></center>
									</div>
									<p className="team-text-title">Amenities</p>
									<p className="team-text-subtitle">{amenities}</p>
									</div>
								</div>
							</Col>
						</Row>
						<hr/>
						<div>
							<h3 className='loc1-similar'>Similar Posts</h3>
							<Carousel
								autoPlay={false}
								autoPlaySpeed={1600}
								ssr
								partialVisible 
								itemClass="image-item"
								responsive={responsive}
								>
									<div class="container-x">
										<div class="card">
											<div class="card__header">
											<img src="https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2021/08/download-23.jpg" alt="card__image" class="card__image" width="600"/>
											</div>
											<div class="card__body">
											<span class="tag tag-red">Plot</span>
											<h5>{title}</h5>
											<p>Lorem ipsum dolor sit amet consectetur</p>
											</div>
										</div>
									</div>
									<div class="container-x">
										<div class="card">
											<div class="card__header">
											<img src="https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2021/08/download-23.jpg" alt="card__image" class="card__image" width="600"/>
											</div>
											<div class="card__body">
											<span class="tag tag-blue">Flat</span>
											<h5>{title}</h5>
											<p>Lorem ipsum dolor sit amet consectetur</p>
											</div>
										</div>
									</div><div class="container-x">
										<div class="card">
											<div class="card__header">
											<img src="https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2021/08/download-23.jpg" alt="card__image" class="card__image" width="600"/>
											</div>
											<div class="card__body">
											<span class="tag tag-brown">Agricultural Land</span>
											<h5>{title}</h5>
											<p>Lorem ipsum dolor sit amet consectetur</p>
											</div>
										</div>
									</div><div class="container-x">
										<div class="card">
											<div class="card__header">
											<img src="https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/wp-content/uploads/2021/08/download-23.jpg" alt="card__image" class="card__image" width="600"/>
											</div>
											<div class="card__body">
											<span class="tag tag-red">Plot</span>
											<h5>{title}</h5>
											<p>Lorem ipsum dolor sit amet consectetur</p>
											</div>
										</div>
									</div>
								</Carousel>
						</div>
					</Col>
					<Col sm={12} md={5} lg={5}>
						<div className='row-item'>
							<h1 className='text-left style1 title1 mt-4'>{title}</h1> 
							<div className="div1">
								<Row>
									<Col md={1} sm={1} xs={1}>
										<FiMapPin size={24} className="ads-call-icons"/>
									</Col>
									<Col md={9} sm={9} xs={9}>
										<p>{city}</p>
									</Col>
									<Col md={2} sm={2} xs={2}>
										<Row>
											<Col md={6} sm={6} xs={6}>
												<p>4.2</p>
											</Col>
											<Col md={6} sm={6} xs={6}>
												<img src={star} height={18} width={18}></img>
											</Col>
										</Row>
									</Col>
								</Row>
								<Row>
									<Col md={4} sm={4} xs={4}>
									<div className='box-card'>
										<p color='gsGraphite  p1' className='text-titleX'>Type</p>
										<p color='gsGraphite' className='text-subtitle'>{type}</p>
									</div>
									</Col>
									<Col md={4} sm={4} xs={4}>
									<div className ='box-card'>
										<p color='gsGraphite' className='text-titleX'>Size</p>
										<p color='gsGraphite' className='text-subtitle'>{size}</p>
									</div>
									</Col>
									<Col md={4} sm={4} xs={4}>
									<div className ='box-card'>
										<p color ='gsGraphite' className='text-titleX'>Price</p>
										<p color ='gsGraphite' className='text-subtitle'>{price}</p>
									</div>
									</Col>
								</Row>
								<div className='row r2'>
									<button type="button" className="check1 check2 "> Contact </button>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</div>
	)}

export default Details;