import React from "react";
import { Nav, NavLink, NavMenu } from "./NavbarElements";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../assets/images/Frame.png";
import sidebar_icon from "../../assets/icon/sidebar_icon.png";
import { IoClose } from "react-icons/io5";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import SideBar from "../Sidebar/sidebar";
import OtpInput from "react-otp-input";
import { FiChevronDown } from "react-icons/fi";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      password: null,
      phone: null,
      loginModal: false,
      validateEmail: false,
      validatePassword: false,
      validatePhone: false,
      validateLogin: true,
      sidebar: false,
      errors: {},
      confirmLogoutModal: false,
      forgetPasswordModal: false,
      emailForgetPasswordView: true,
      otpForgetPasswordView: false,
      confirmPasswordView: false,
      otpFP: null,
      optID: null,
      dropdownView: false,
      dropdownView2: false,
      rememberMeCheck: true
    };
    this.openModal = this.openModal.bind(this);
    this.confirmLogoutModal = this.confirmLogoutModal.bind(this);
    this.forgetPasswordModal = this.forgetPasswordModal.bind(this);
    this.loginCheck = this.loginCheck.bind(this);
    this.login = this.login.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.toastFunct = this.toastFunct.bind(this);
    this.successToast = this.successToast.bind(this);
    this.OTPsuccessToast = this.OTPsuccessToast.bind(this);
    this.OTPVerifiedToast = this.OTPVerifiedToast.bind(this);
    this.errorToast = this.errorToast.bind(this);
    this.OTPErrorToast = this.OTPErrorToast.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.clearAsyncStorage = this.clearAsyncStorage.bind(this);
    this.rememberMeCheck = this.rememberMeCheck.bind(this);
  }
  handleOTPFPChange = (otp) => {
    this.setState({ otpFP: otp });
  };
  handleEmail(event) {
    var re = /\S+@\S+\.\S+/;
    var result = re.test(event.target.value);
    let errors = this.state.errors;
    if(re.test(event.target.value))
    {
      errors["email"] = null
      this.setState({ errors: errors});
      console.log(result)
    }
    else{
      errors["email"] = "Invalid Email";
      this.setState({ errors: errors });
    }
    this.setState({
      email: event.target.value,
    });
  }
  rememberMeCheck(e) {
    this.setState({ rememberMeCheck: !e.target.checked });
  }
  navigateRequest = () => {
    this.props.history.push("/create-order");
  };
  clearAsyncStorage = async () => {
    localStorage.clear();
    window.location.reload();
  };
  confirmLogoutModal = () => {
    this.clearAsyncStorage();
    this.setState({ confirmLogoutModal: false });
  };
  forgetPasswordModal = () => {
    this.setState({ forgetPasswordModal: !this.state.forgetPasswordModal });
  };
  toggleButton = () => {
    this.setState({
      sideBar: !this.state.sideBar,
    });
  };
  handlePassword(event) {
    this.setState({
      password: event.target.value
    });
    let errors = {};
    var password = this.state.password;
    var passwordLength = password.length;
    console.log("LENGTH ====>", passwordLength)
    if (passwordLength > 1) {
      this.setState({ errors: errors});
    } else {
      errors["password"] = "Cannot be empty";
      this.setState({ errors: errors });
    }
  }
  forgotPassword = async () => {
    const {email, errors} = this.state;
    let emailWarning = this.state.errors["email"];
    console.log(email)
    if(email != null){
      if(emailWarning == null)
      {
        this.toastFunct();
        let passwordDetails = {
          emailId: email,
        };
        let ld = JSON.stringify(passwordDetails);
        console.log(ld)
        await fetch("https://backend.myplotpic.com/api/forgotPassword", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: ld,
        })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.message === "Authentication error.") {
            this.errorToast();
          } else if (responseJson.error === "unable to send the mail") {
            this.OTPErrorToast();
          } else {
            this.OTPsuccessToast();
            this.setState({
              otpForgetPasswordView: true,
              emailForgetPasswordView: false,
              optID: responseJson.data.id._id,
            });
          }
        });
      }
    }
  };
  forgetPasswordOTPVerify = async () => {
    let passwordDetails = {
      id: this.state.optID,
      otp: this.state.otpFP,
    };
    let ld = JSON.stringify(passwordDetails);
    console.log(ld);
    await fetch("https://backend.myplotpic.com/api/checkForgotPasswordOTP", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: ld,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (
          responseJson.message ===
          "OTP is correct. now, you can change the password"
        ) {
          this.OTPVerifiedToast();
          this.setState({
            otpForgetPasswordView: false,
            emailForgetPasswordView: false,
            confirmPasswordView: true,
          });
        } else {
          this.OTPErrorToast();
        }
      });
  };
  resetPasswordVerify = async () => {
    let passwordDetails = {
      id: this.state.optID,
      password: this.state.password,
    };
    let ld = JSON.stringify(passwordDetails);
    console.log(ld);
    await fetch("https://backend.myplotpic.com/api/resetPassword", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: ld,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.message === "password changed sucessfully") {
          this.passwordResetToast();
          this.setState({
            otpForgetPasswordView: false,
            emailForgetPasswordView: false,
            confirmPasswordView: false,
            loginModal: true,
            forgetPasswordModal: false,
          });
        } else {
          this.errorToast();
        }
      });
  };
  openModal() {
    this.setState({ loginModal: true });
  }
  handlePhone(object) {
    if (object.target.value.length < object.target.maxLength) {
      this.setState({ phone: object.target.value });
    }
    let errors = {};
    var phone = this.state.phone;
    var phoneLength = phone.length;
    console.log("LENGTH ====>", phoneLength)
    if (phoneLength >= 9) {
      this.setState({ errors: errors});
    } else {
      errors["phone"] = "Must be 10 digits";
      this.setState({ errors: errors });
    }
  }
  toastFunct() {
    toast.info("Loading ..", {
      position: "bottom-center",
      autoClose: 1200,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  successToast() {
    toast.success("Authenticated", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  OTPsuccessToast() {
    toast.success("OTP Sent", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  OTPVerifiedToast() {
    toast.success("OTP Verified", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  passwordResetToast() {
    toast.success("Password Changed", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  errorToast() {
    toast.error("Authentication Error", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  OTPErrorToast() {
    toast.error("Error: Unable to send OTP", {
      position: "bottom-center",
      autoClose: 2700,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  loginCheck() {
    var email = this.state.email;
    var emailLength = email.length;

    var password = this.state.password;
    var passwordLength = password.length;

    var phone = this.state.phone;
    var phoneLength = phone.length;

    if(emailLength > 7 || phoneLength === 10 && passwordLength > 4){
      if(this.state.errors === {}){
      }
      else{
        this.setState({
          validateLogin: true
        })
        console.log("NO ERRORS");
        this.login()
      }
    }
  }
  login = async () => {
    if (this.state.validateLogin === true) {
      this.toastFunct();
      let loginDetails = {
        email: this.state.email,
        password: this.state.password,
        contactno: this.state.phone,
      };
      let ld = JSON.stringify(loginDetails);
      console.log("Login Details ====> " + ld);
      await fetch("https://backend.myplotpic.com/api/signin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: ld,
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson);
          if (responseJson.message === "Authentication error.") {
            this.errorToast();
          } else if (responseJson.message === "Account does not exist") {
            this.errorToast();
          } else {
            this.successToast();
            this.storeData(responseJson.user);
            this.storeToken(responseJson.token);
            this.setState({ loginModal: false });
          }
        });
    }
  };
  storeData = async (userData) => {
    // const { userData } = this.state;
    localStorage.setItem("userData", JSON.stringify(userData));
    console.log("<=====UserData Saved=====>", JSON.stringify(userData));
  };
  storeToken = async (token) => {
    localStorage.setItem("token", JSON.stringify(token));
    console.log("<=====Token Saved=====>");
  };
  render() {
    return (
      <>
        <Modal
          show={this.state.loginModal}
          backdrop="static"
          keyboard={false}
          centered
          dialogClassName="border-radius-2"
        >
          <ModalBody>
            <div>
              <IoClose
                size={25}
                className="closeIcon"
                onClick={() => this.setState({ loginModal: false })}
              />
              <center>
                <p
                  className="loginModalTxt"
                  style={{ color: "#02486c", textAlign: "center", marginLeft: 35 }}
                >
                  Login
                </p>
              </center>
              <p className="loginModal_txt">Phone :</p>
              <input
                className="loginInput"
                placeholder="Phone"
                value={this.state.phone}
                type = "number" maxLength = "11"
                onChange={this.handlePhone}
                onFocus={this.handlePhone}
              />
              {
                this.state.errors["phone"] ? (
                  <p id="marginInputs" className="loginErrorTxt">
                    {this.state.errors["phone"]}
                  </p>
                ) : (
                  <></>
                )
              }
              <p className="loginModal_txt">Email :</p>
              <input
                className="loginInput"
                type="text"
                placeholder="Email"
                inputMode="email"
                enterKeyHint="next"
                onChange={this.handleEmail}
              />
              {
                this.state.errors["email"] ? (
                  <p id="marginInputs" className="loginErrorTxt">
                    {this.state.errors["email"]}
                  </p>
                ) : (
                  <></>
                )
              }
              <p className="loginModal_txt">
                Password :
              </p>
              <input
                className="loginInput"
                type="password"
                placeholder="Password"
                onChange={this.handlePassword}
              />
              {
                this.state.errors["password"] ? (
                  <p id="marginInputs" className="loginErrorTxt">
                    {this.state.errors["password"]}
                  </p>
                ) : (
                  <></>
                )
              }
            </div>
            <Row style={{ marginTop: "6%"}}>
              <Col md={1} xs={1} sm={1}>
                <input
                  type="checkbox"
                  onClick={this.rememberMeCheck}
                  checked={!this.state.rememberMeCheck}
                ></input>
              </Col>
              <Col md={4} xs={5} sm={5}>
                <label className="login_txt">
                  Remember me
                </label>
              </Col>
              <Col md={3} xs={1} sm={1}></Col>
              <Col md={4} xs={5} sm={5}>
              <span
                className="forgotPass-hover"
                onClick={() =>
                  this.setState({ loginModal: false, forgetPasswordModal: true })
                }
              >
                Forgot Password?
              </span>
              </Col>
            </Row>
            
            <center>
              <button
                style={{
                  borderRadius: 60,
                  backgroundColor: "#00476b",
                  marginBottom: "5%",
                  width: 180
                }}
                type="submit"
                onClick={this.login}
              >
                Login
              </button>
            </center>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ModalBody>
        </Modal>

        <Modal
          show={this.state.forgetPasswordModal}
          backdrop="static"
          keyboard={false}
          centered
          dialogClassName="border-radius-5"
        >
          <ModalBody>
            <div style={{ paddingLeft: "10%", paddingRight: "10%" }}>
              <IoClose
                size={25}
                className="closeIcon"
                onClick={() => this.setState({ forgetPasswordModal: false })}
              />
              <center>
                <p
                  className="loginModalTxt"
                  style={{
                    color: "#02486c",
                    textAlign: "center",
                    marginBottom: "5%",
                  }}
                >
                  Forgot Password
                </p>
              </center>
              {this.state.emailForgetPasswordView ? (
                <>
                  <p className="ct-frm-lbl">Email :</p>
                  <input
                    className="forgetPassword_input"
                    type="text"
                    placeholder="Email"
                    inputMode="email"
                    value={this.state.email}
                    onChange={this.handleEmail}
                  />
                  {
                    this.state.errors["email"] ? (
                      <p className="errorText_forgetPassword">
                        {this.state.errors["email"]}
                      </p>
                    ) : (
                      <></>
                    )
                  }
                  <center>
                    <button
                      style={{
                        marginBottom: "5%",
                        marginTop: "6%",
                      }}
                      className="requestQuote"
                      type="submit"
                      onClick={this.forgotPassword}
                    >
                      Send OTP
                    </button>
                  </center>
                </>
              ) : (
                <></>
              )}
              {this.state.otpForgetPasswordView ? (
                <>
                  <center>
                    <p className="ct-frm-lbl">Enter OTP</p>
                    <OtpInput
                      value={this.state.otpFP}
                      onChange={this.handleOTPFPChange}
                      isInputNum={true}
                      containerStyle="otp-block"
                      inputStyle={{
                        width: "3.5rem",
                        height: "3.5rem",
                        margin: "20px 1rem",
                        fontSize: "1.5rem",
                        color: "#5B5B5B",
                        borderRadius: 4,
                        border: "2px solid #ba7105",
                      }}
                      numInputs={4}
                      separator={<span>-</span>}
                    />
                    <Row>
                      <Col md={6}>
                        <button
                          style={{
                            marginBottom: "5%",
                            marginTop: "7%",
                          }}
                          className="requestQuote2"
                          type="submit"
                          onClick={this.forgetPasswordOTPVerify}
                        >
                          Submit
                        </button>
                      </Col>
                      <Col>
                        <button
                          style={{
                            marginBottom: "5%",
                            marginTop: "7%",
                          }}
                          className="requestQuote"
                          type="submit"
                          onClick={() =>
                            this.setState({
                              otpForgetPasswordView: false,
                              emailForgetPasswordView: true,
                              confirmPasswordView: false,
                            })
                          }
                        >
                          Back
                        </button>
                      </Col>
                    </Row>
                  </center>
                </>
              ) : (
                <></>
              )}
              {this.state.confirmPasswordView ? (
                <>
                  <center>
                    <p className="ct-frm-lbl">Enter New Password</p>
                    <input
                      className="in-cus-ct input_"
                      type="password"
                      placeholder="Password"
                      onChange={this.handlePassword}
                    />
                    <button
                      style={{
                        marginBottom: "5%",
                        marginTop: "7%",
                      }}
                      className="requestQuote2"
                      type="submit"
                      onClick={this.resetPasswordVerify}
                    >
                      Submit
                    </button>
                  </center>
                </>
              ) : (
                <></>
              )}
            </div>
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </ModalBody>
        </Modal>
        <Modal
          show={this.state.confirmLogoutModal}
          backdrop="static"
          keyboard={false}
          centered
          dialogClassName="border-radius-1"
          onHide={() => this.setState({ confirmLogoutModal: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Log Out
            </Modal.Title>
          </Modal.Header>
          <ModalBody>
            <div>
              <center>
                <p className="logoutModal-txt">
                  Are you sure you want to logout?
                </p>
                <br />
                <Row>
                  <Col md={6}>
                    <button
                      className="requestQuote"
                      onClick={this.confirmLogoutModal}
                    >
                      <p>Confirm</p>
                    </button>
                  </Col>
                  <Col md={6}>
                    <button
                      className="requestQuote2"
                      onClick={() =>
                        this.setState({ confirmLogoutModal: false })
                      }
                    >
                      <p className="register-modal-btn-txt">Cancel</p>
                    </button>
                  </Col>
                </Row>
              </center>
            </div>
          </ModalBody>
        </Modal>
        <Nav>
          <div className="side-bar">
            {this.state.sideBar ? (
              <Row>
                <Col onClick={() => this.setState({ sideBar: false })}>
                  <SideBar sideBarShow={this.state.sideBar} />
                </Col>
                <Col>
                  <div className="mobile-side-img-div3">
                    <IoClose
                      size={40}
                      className="closeIcon_sidebar"
                      onClick={() => this.setState({ sideBar: false })}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col>
                  <div className="mobile-side-img-div2">
                    <img
                      alt="sidebar"
                      src={sidebar_icon}
                      className="sidebar-icon"
                      onClick={() => this.setState({ sideBar: true })}
                    />
                  </div>
                </Col>
                <Col>
                  <div className="mobile-side-img-div">
                    <Link to="/">
                      <center>
                        <img
                          alt="logo"
                          src={logo}
                          style={{ height: 40, width: 135, marginLeft: 10 }}
                        />
                      </center>
                    </Link>
                  </div>
                </Col>
              </Row>
            )}
          </div>
          <NavMenu>
            <Row>
              <Col md={2}>
                <div>
                  <Link to="/">
                    <img
                      alt="logo"
                      src={logo}
                      style={{ height: 40, width: 135 }}
                    />
                  </Link>
                </div>
              </Col>
              <Col md={10}>
                <div id="new-alignment-navbar">
                  <NavLink
                    to="/about-us"
                    style={{ marginRight: "2.5%" }}
                    className="nav-change"
                    onMouseOver={() => this.setState({dropdownView: false, dropdownView2: false})}
                  >
                    About
                  </NavLink>
                  <NavLink
                    to="/ads"
                    style={{ marginRight: "2.5%" }}
                    className="nav-change"
                    onMouseOver={() => this.setState({dropdownView: false, dropdownView2: false})}
                  >
                    Ads
                  </NavLink>
                  <NavLink
                    to="/create-order"
                    style={{ marginRight: "2.5%" }}
                    className="nav-change"
                    onMouseOver={() => this.setState({dropdownView: false, dropdownView2: false})}
                  >
                    Request
                  </NavLink>
                  {/*<p
                    className="navbar_txt_reserve"
                    onClick={this.navigateRequest}
                    onMouseOver={() => this.setState({dropdownView: true, dropdownView2: false})}
                    style={{
                      marginBottom: "1%",
                      marginTop: "-0.01%",
                    }}
                  >
                    Request
                    <FiChevronDown
                      size={20}
                      className="dropdown_icon"
                    />
                  </p>
                  
                  {
                    this.state.dropdownView?
                      <div className="categories-hover-div" onMouseOver={() => this.setState({dropdownView: true, dropdownView2: false})}>
                       <center>
                       <NavLink
                            to="/create-order"
                            style={{ height: "50%"}}
                            className="nav-change-drop"
                            onMouseOver={() => this.setState({dropdownView: true, dropdownView2: false})}
                          >
                            Order
                        </NavLink>
                        <div style={{marginTop: 12, marginBottom:12}}></div>
                        {/*<NavLink
                            to="/Contact"
                            className="nav-change-drop"
                            style={{height: "50%"}}
                            onMouseOut={() => this.setState({dropdownView: false, dropdownView2: false})}
                        >
                            Contact
                  </NavLink>
                       </center>
                      </div>
                    :
                    <></>
                  }
                  */}
                  {localStorage.getItem("userData") ? 
                    <></>
                  : (
                    <>
                      <p
                        className="navbar_txt_reserve"
                        onClick={this.openModal}
                        onMouseOver={() => this.setState({dropdownView2: true, dropdownView: false})}
                        style={{
                          marginBottom: "1%",
                          marginTop: "-0.01%",
                        }}
                      >
                        Account
                        <FiChevronDown
                          size={20}
                          className="dropdown_icon"
                          onClick={() => this.setState({ loginModal: false })}
                        />
                      </p>
                      {
                        this.state.dropdownView2?
                          <div className="categories-hover-div2" onMouseOver={() => this.setState({dropdownView2: true, dropdownView: false})}>
                          <center>
                            <p
                              className="nav-change-drop"
                              onClick={this.openModal}
                              onMouseOver={() => this.setState({dropdownView2: true, dropdownView: false})}
                            >
                              Login
                            </p>
                            <div style={{marginTop: 12, marginBottom:12}}></div>
                            <NavLink
                              to="/register"
                              className="nav-change-drop"
                              style={{height: "50%"}}
                              onMouseOut={() => this.setState({dropdownView2: false, dropdownView: false})}
                            >
                              Register
                            </NavLink>
                          </center>
                          </div>
                        :
                        <></>
                      }
                    </>
                    
                    
                  )}
                  {
                    localStorage.getItem("userData") ?
                      <NavLink
                        to="/notifications"
                        className="nav-change"
                        style={{ marginRight: "1%",}}
                        onMouseOver={() => this.setState({dropdownView2: false, dropdownView: false})}
                      >
                        Notifications
                      </NavLink>
                    :
                    <></>
                  }
                  {
                    localStorage.getItem("userData") ?
                      <p
                        className="navbar_txt"
                        onClick={() =>
                          this.setState({ confirmLogoutModal: true })
                        }
                        onMouseOver={() => this.setState({dropdownView2: false, dropdownView: false})}
                        style={{ marginRight: "1%", marginTop: "-0.01%" }}
                      >
                        Logout
                      </p>
                    :
                    <></>
                  }
                  <div onMouseOver={() => this.setState({dropdownView: false, dropdownView2: false})}>
                    <Link to="/ExclusiveProjects">
                      <button className="exclusiveBtn" >Exclusive</button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </NavMenu>
        </Nav>
      </>
    );
  }
}

export default Navbar;
