import React from "react";
import { withRouter } from "react-router-dom";
import Footer from "../../components/Footer/footer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Loader from "react-js-loader";
import Select from "react-select";
import Button from "react-bootstrap/Button";
import { Pagination } from "react-pagination-bar";

import Navbar from "../../components/NavBar/index";
import { MdTune } from "react-icons/md";

import AdsCard from "../../components/AdsCard/AdsCard.js";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import error404 from "../../assets/404error.png";

import "./ads.css";
import "react-slideshow-image/dist/styles.css";
import "react-pagination-bar/dist/index.css";

const filterOptions = [
  { value: "Plot", label: "Plot" },
  { value: "Agri", label: "Agricultural Land" },
  { value: "Flat", label: "Flat" },
];

const cityOptions = [
  { value: "Visakhapatnam", label: "Visakhapatnam" },
  { value: "Bhubhuneshwar", label: "Bhubhuneshwar" },
  { value: "Chennai,", label: "Chennai" },
  { value: "Hyderabad", label: "Hyderabad" },
  { value: "Vizag", label: "Vizag" },
  { value: "Vijaywada", label: "Vijaywada" },
  { value: "Guntur", label: "Guntur" },
  { value: "Amravati", label: "Amravati" },
  { value: "Bengaluru", label: "Bengaluru" },
  { value: "Tirupati", label: "Tirupati" },
];

const priceOptions = [
  { value: "type1", label: "Below 25 Lakhs" },
  { value: "type2", label: "25 Lakhs to 50 Lakhs" },
  { value: "type3", label: "50 Lakhs to 1 Cr" },
  { value: "type4", label: "1 Cr. to 2 Cr" },
  { value: "type5", label: "2 Cr. to 3 Cr." },
  { value: "type6", label: "3 Cr. to 5 Cr." },
  { value: "type7", label: "Above 5 Cr." },
];
const postOptions = [
  { value: "Myplotpic", label: "Myplotpic" },
  { value: "Owner", label: "Direct Owner" },
  { value: "Agent", label: "Agent" },
];

class ExclusiveProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adsData: [],
      adsArrayLength: 85,
      adsFilteredData: [],
      isMainPage: true,
      loaderModal: true,
      searchQuery: null,
      filterValue: "Plot",
      isFilterActive: false,
      currentPage: 1,
      previousState: 0,
      nextState: 10,
      options: [],
      city: "Visakhapatnam",
      price: "type2",
      post: "Myplotpic",
      updateAsperUrl: false,
      location: "",
      filterModal: false,
    };
    this.navigatetoAdsPage = this.navigatetoAdsPage.bind(this);
    this.getData = this.getData.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handlePost = this.handlePost.bind(this);
    this.handleLocation = this.handleLocation.bind(this);
  }
  handleLocation(event) {
    this.setState({
      location: event.target.value,
    });
  }
  navigatetoAdsPage = () => {
    this.setState({
      filterModal: false,
    });
    window.localStorage.setItem("cityParam", this.state.city);
    window.localStorage.setItem("filterParam", this.state.filterValue);
    window.localStorage.setItem("postParam", this.state.post);
    window.localStorage.setItem("priceParam", this.state.price);
    window.localStorage.setItem("landmarkParam", this.state.location);
    this.getData();
  };
  handleFilter = (landType) => {
    this.setState({ filterValue: landType.value });
    console.log(this.state.filterValue);
  };
  handleCity = (city) => {
    this.setState({ city: city.value });
  };
  handlePost = (post) => {
    this.setState({ post: post.value });
  };
  handlePrice = (price) => {
    this.setState({ price: price.value });
  };
  getData = () => {
    fetch(`https://backend.myplotpic.com/api/getAdvertisement_exclusive`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log("OUTPUT ==> ", responseJson);
        this.setState({
          adsData: responseJson,
          adsFilteredData: responseJson,
          adsArrayLength: responseJson.length,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  componentDidMount() {
    this.getData();
    setTimeout(() => {
      this.setState({ loaderModal: false });
    }, 2000);
  };
  changePage(pageNumber) {
    if (pageNumber === 1) {
      this.setState({ previousState: 0, currentPage: pageNumber });
    }
    this.setState({
      previousState: this.state.currentPage - 1,
      currentPage: pageNumber,
    });
  }
  showPagination = (totalPages) => {
    return (
      <div className="cards-ads margin-pagination">
        <center>
          <Pagination
            totalItems={totalPages}
            onlyPageNumbers={true}
            itemsPerPage={10}
            onPageСhange={(pageNumber) => this.changePage(pageNumber)}
            customClassNames={{
              rpbItemClassName: "custom-item",
              rpbItemClassNameActive: "custom-item--active",
              rpbGoItemClassName: "custom-go-item",
              rpbItemClassNameDisable: "custom-item--disable",
              rpbProgressClassName: "custom-progress-bar",
              rpbRootClassName: "custom-root",
            }}
          />
        </center>
      </div>
    );
  };

  showPaginationMobile = (totalPages) => {
    return (
      <div className="cards-ads margin-pagination">
        <center>
          <Pagination
            totalItems={totalPages}
            onlyPageNumbers={true}
            itemsPerPage={10}
            pageNeighbours={1}
            onPageСhange={(pageNumber) => this.changePage(pageNumber)}
            customClassNames={{
              rpbItemClassName: "custom-item",
              rpbItemClassNameActive: "custom-item--active",
              rpbGoItemClassName: "custom-go-item",
              rpbItemClassNameDisable: "custom-item--disable",
              rpbProgressClassName: "custom-progress-bar",
              rpbRootClassName: "custom-root",
            }}
          />
        </center>
      </div>
    );
  };
  //MAIN DATA RENDER
  renderData = () => {
    var currentPage = this.state.currentPage * 10;
    var previousState = this.state.previousState * 10;
    var endpoint = "https://backend.myplotpic.com/uploads/";
    return (
      <div>
        {this.state.adsArrayLength >= 1 ? (
          this.state.adsData
            .slice(previousState, currentPage)
            .map((item, i) => <AdsCard key={i} data={item}></AdsCard>)
        ) : (
          <center>
            <img
              alt="logo"
              src={error404}
              style={{ height: 400, width: 400 }}
            />
          </center>
        )}
      </div>
    );
  };

  brochure = (fileName) => {
    window.open("https://backend.myplotpic.com/uploads/" + fileName);
  };
  render() {
    return (
      <div>
        <div className="container-ads">
          <div className="grad-box">
            <Navbar />
          </div>
          <div>
            <p className="smartVisit-title">Smart visit your site</p>
            <p className="buy_txt">Buy</p>
            <center>
              <div
                className="new-bx-ads-homestyle border padding-4"
                style={{ padding: 5 }}
              >
                <Row>
                  <Col md={2} xs={6}>
                    <div className="searchCard-dividerRight">
                        <Select
                          value={filterOptions.filter(
                            ({ value }) => value === this.state.filterValue
                          )}
                          isSearchable={false}
                          onChange={this.handleFilter}
                          placeholder={<div>Select Type</div>}
                          options={filterOptions}
                          className="selectCity"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              boxShadow: "none",
                              border: "none",
                              backgroundColor: "transparent",
                              marginTop: -5,
                              paddingRight: 20,
                              width:"100%"
                            }),
                            indicatorSeparator: (provided, state) => ({
                              ...provided,
                              display:"none"
                            })
                          }}
                        />
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="searchCard-dividerRight">
                      <center>
                        <Select
                          value={cityOptions.filter(
                            ({ value }) => value === this.state.city
                          )}
                          onChange={this.handleCity}
                          placeholder={<div>City</div>}
                          isSearchable={false}
                          options={cityOptions}
                          className="selectCity"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              boxShadow: "none",
                              border: "none",
                              backgroundColor: "transparent",
                              color: "black",
                              marginTop: -5,
                              marginLeft: -32,
                              width:"100%"
                            }),
                            indicatorSeparator: (provided, state) => ({
                              ...provided,
                              display:"none"
                            })
                          }}
                        />
                      </center>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="searchCard-dividerRight-Input">
                      <center>
                        <input
                          className="textField-new"
                          type="text"
                          value={this.state.location}
                          placeholder="Location"
                          onChange={this.handleLocation}
                        ></input>
                      </center>
                    </div>
                  </Col>
                  <Col md={2} xs={12} sm={12}>
                    <div className="searchCard-dividerRight">
                      <center>
                        <Select
                          value={priceOptions.filter(
                            ({ value }) => value === this.state.price
                          )}
                          onChange={this.handlePrice}
                          placeholder={<div>Price</div>}
                          options={priceOptions}
                          isSearchable={false}
                          className="selectCity"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              boxShadow: "none",
                              border: "none",
                              backgroundColor: "transparent",
                              color: "#000000",
                              marginTop: -5,
                              marginLeft: -25,
                              width:"100%"
                            }),
                            indicatorSeparator: (provided, state) => ({
                              ...provided,
                              display:"none"
                            })
                          }}
                        />
                      </center>
                    </div>
                  </Col>
                  <Col md={2} xs={6}>
                    <div className="searchCard-dividerRight">
                      <center>
                        <Select
                          value={postOptions.filter(
                            ({ value }) => value === this.state.post
                          )}
                          onChange={this.handlePost}
                          placeholder={<div>Posted by</div>}
                          options={postOptions}
                          isSearchable={false}
                          className="selectCity"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              boxShadow: "none",
                              border: "none",
                              backgroundColor: "transparent",
                              color: "#000000",
                              marginTop: -5,
                              marginLeft: -38,
                              width:"100%"
                            }),
                            indicatorSeparator: (provided, state) => ({
                              ...provided,
                              display:"none"
                            })
                          }}
                        />
                      </center>
                    </div>
                  </Col>
                  <Col md={2} xs={12}>
                    <center>
                      <Button
                        variant="primary"
                        id="searchButton-home_searchCard"
                        onClick={this.navigatetoAdsPage}
                      >
                        Search
                      </Button>
                    </center>
                  </Col>
                </Row>
              </div>
              <div
                className="new-bx-ads-homestyle-mobile border padding-4"
                style={{ padding: 5 }}
              >
                <div>
                  <center>
                    <Row>
                      <Col xs={9}>
                        <input
                          className="textField-new"
                          type="text"
                          value={this.state.location}
                          placeholder="Enter Location"
                          onChange={this.handleLocation}
                        ></input>
                      </Col>
                      <Col xs={3}>
                        <MdTune
                          size={35}
                          className="filterIcon"
                          onClick={() => this.setState({ filterModal: true })}
                        />
                      </Col>
                    </Row>
                  </center>
                </div>
                <Modal
                  show={this.state.filterModal}
                  backdrop="static"
                  keyboard={false}
                  size="sm-down"
                  dialogClassName="search-modal-mobile"
                  centered
                >
                  <ModalBody>
                    <div style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: 10, paddingBottom: 10 }}>
                      <div className="searchCard-dividerRight">
                        <center>
                          <Select
                            value={filterOptions.filter(
                              ({ value }) => value === this.state.filterValue
                            )}
                            onChange={this.handleFilter}
                            placeholder={<div>Select Type</div>}
                            options={filterOptions}
                            className="selectCity"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: "transparent",
                                marginTop: -5,
                              }),
                            }}
                          />
                        </center>
                      </div>
                      <br />
                      <div className="searchCard-dividerRight">
                        <center>
                          <Select
                            value={cityOptions.filter(
                              ({ value }) => value === this.state.city
                            )}
                            onChange={this.handleCity}
                            placeholder={<div>City/Town</div>}
                            options={cityOptions}
                            className="selectCity"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: "transparent",
                                color: "black",
                                marginTop: -5,
                              }),
                            }}
                          />
                        </center>
                      </div>
                      <br />
                      <div className="searchCard-dividerRight">
                        <center>
                          <Select
                            value={priceOptions.filter(
                              ({ value }) => value === this.state.price
                            )}
                            onChange={this.handlePrice}
                            placeholder={<div>Price</div>}
                            options={priceOptions}
                            className="selectCity"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: "transparent",
                                color: "#000000",
                                marginTop: -5,
                              }),
                            }}
                          />
                        </center>
                      </div>
                      <br />
                      <div className="searchCard-dividerRight">
                        <center>
                          <Select
                            value={postOptions.filter(
                              ({ value }) => value === this.state.post
                            )}
                            onChange={this.handlePost}
                            placeholder={<div>Posted by</div>}
                            options={postOptions}
                            className="selectCity"
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: "transparent",
                                color: "#000000",
                                marginTop: -5,
                              }),
                            }}
                          />
                        </center>
                      </div>
                      <br />
                      <center>
                        <Row>
                          <Col md={6} xs={6} sm={6}>
                            <Button
                              variant="primary"
                              id="searchButton-home-white"
                              onClick={() => this.setState({ filterModal: false })}
                            >
                              Close
                            </Button>
                          </Col>
                          <Col md={6} xs={6} sm={6}>
                            <Button
                              variant="primary"
                              id="searchButton-home"
                              onClick={this.navigatetoAdsPage}
                            >
                              Search
                            </Button>
                          </Col>
                        </Row>
                      </center>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </center>
          </div>
          {this.state.loaderModal ? (
            <div className="cards-ads">
              <Loader
                type="spinner-circle"
                bgColor={"#02486c"}
                title={"Loading"}
                color={"#02486c"}
                size={80}
              />
            </div>
          ) : (
            <></>
          )}
          {this.state.loaderModal ? (
            <></>
          ) : (
            <div id="renderdata-alignment">{this.renderData()}</div>
          )}
          <div className="pagination-forPC">
            {this.showPagination(this.state.adsArrayLength)}
          </div>
          <center>
            <div className="pagination-forMobile">
              {this.showPaginationMobile(this.state.adsArrayLength)}
            </div>
          </center>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ExclusiveProjects);
