import React from "react";
import './connect.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { ImLocation } from "react-icons/im";
import office from "../../assets/office.png";
import Navbar from "../../components/NavBar/index";

const queryParams =  new URLSearchParams(window.location.search);

class Connect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        type: "",
        name: "",
        message: ""
    }
    this.handleMessage = this.handleMessage.bind(this);
  };
  componentDidMount = () => {
    this.setState({
      type: queryParams.get("type"),
      name: queryParams.get("name"),
      message: queryParams.get("message")
    })
  }
  handleMessage = (event) => {
    this.setState({
      message: event.target.value,
    });
  };
    render(){
      return (
      <div>
        <Navbar />
        <div className="why-us-contact-ct">
          <Row>
          <Col md={5} xs={12} sm={12}>
              <div className="contact-div-first-home-ct">
                  <p className="contact-div1-title-ct">Contact Information</p>
                  <p className="contact-div1-subtitle-ct">Fill up the form and our team will get back to you in 24 hrs</p>
                  <div className="contact-icon-first-home-ct">
                    <Row>
                      <Col md={1}>
                        <FaPhoneAlt
                          size={22}
                          className="loc-icons-ct"
                        />
                      </Col>
                      <Col md={5}>
                        <p className="contact-div1-subtitle-home-ct number">+91 724-955-6789</p>
                      </Col>
                    </Row>
                  </div>
                  <div className="contact-icon-second-home-ct">
                    <Row>
                      <Col md={1}>
                        <IoIosMail
                          size={28}
                          className="loc-icons-ct"
                        />
                      </Col>
                      <Col md={4}>
                        <p className="contact-div1-subtitle-home-ct number" onClick={() => window.location = 'mailto:asb@myplotpic.com'}>asb@myplotpic.com</p>
                      </Col>
                    </Row>
                  </div>
                  <div className="contact-icon-second-home-ct">
                    <Row>
                      <Col md={1}>
                        <ImLocation
                          size={28}
                          className="loc-icons-ct"
                        />
                      </Col>
                      <Col md={9}>
                        <p className="contact-div1-subtitle-home-ct number-2" onClick={() => window.open("https://maps.google.com?q="+19.05033358336438+","+73.06530312559231 )}>
                            My Plot Pic Private Limited,<br/>
                            D.No 5-1, Survey No 159, Marikavalasa,<br/>
                            Next to Bisleri Water Plant, Madhurawada,<br/>
                            Visakapatnam, AP, India. 530048
                        </p>
                      </Col>
                    </Row>
                  </div>
              </div>
            </Col>
            <Col md={7} xs={12} sm={12}>
              <div className="sc-hm-one-contact-ct">
                <div className="sc-hm-one-in-ct">
                  <div className="home-contact-inner-ct">
                    <Row style={{marginBottom:"7%", alignItems:"center"}}>
                        <Col md={2} xs={4} sm={4}>
                            <img
                                alt="sample"
                                src={office}
                                className="office-img"
                            />
                        </Col>
                        <Col>
                            <p className="builder-div1-title-ct">{this.state.name}</p>
                            <p className="builder-div1-subtitle-ct">{this.state.type}</p>  
                        </Col>
                    </Row>
                      
                      <input
                        className="in-cus-ct-ct input_ct"
                        type="text"
                        placeholder="Name"
                        enterKeyHint="next"
                      />
                      <input
                        className="in-cus-ct-ct input_ct"
                        type="text"
                        placeholder="Email"
                        inputMode="email"
                        enterKeyHint="next"
                      />
                      <input
                        className="in-cus-ct-ct input_ct"
                        type="text"
                        placeholder="Phone"
                        inputMode="email"
                        enterKeyHint="next"
                      />
                      <textarea
                        className="messageInput-ct"
                        type="text"
                        placeholder="Message"
                        value={this.state.message}
                        onChange={this.handleMessage}
                      />
                      <center>
                        <button
                          className="requestQuote-homecontact-ct"
                        >
                          Submit
                        </button>
                      </center>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      );
    }
  }
export default Connect;