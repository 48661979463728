import React from "react";
import { withRouter } from "react-router-dom";
import "./footer.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/Frame.png";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Footer extends React.Component {
  render() {
    return (
      <div>
        <Row className="footer_contant">
          <Col sm={12} md={4} lg={4}>
            <div className="quick-links">
              <Link className="links_text" to="/">
                <img alt="logo" src={logo} className="footer_logo" />
              </Link>
            </div>
            <div className="links">
              <p className="links">
                <Link className="links_text" to="/about-us">
                  About Us
                </Link>
              </p>
              <p className="links">
                <Link className="links_text" to="/terms-of-use">
                  Terms Of Use
                </Link>
              </p>
              <p className="links">
                <Link className="links_text" to="/refund-policy">
                  Refund/Cancellation Policy
                </Link>
              </p>
              <p className="links">
                <Link className="links_text" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </p>
              <p className="links">
                <Link className="links_text" to="/terms-of-use">
                  Terms and Conditions
                </Link>
              </p>
            </div>
          </Col>

          <Col sm={12} md={4} lg={4}>
            <div className="links">
              <p className="links">
                <Link className="links_text" to="/about-us">
                  About
                </Link>
              </p>
              <p className="links">
                <Link className="links_text" to="/services">
                  Services
                </Link>
              </p>
              {/*<p className="links">
                  <Link className="links_text" to="/contact">
                    Contact
                  </Link>
                </p>*/
              }
              <p className="links">
                <Link className="links_text" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </p>
              {localStorage.getItem("userData") ? (
                <p className="links">
                  <Link className="links_text" to="/status">
                    Order Status
                  </Link>
                </p>
              ) : (
                <></>
              )}
            </div>
          </Col>

          <Col sm={12} md={4} lg={4}>
            <p className="footer_info">
              Our property monitoring services are available through
              subscription. Please visit services page or WhatsApp{" "}
              <a href="https://wa.me/917249556789?text=Hello">
                <span style={{ color: "#4da6ff" }}>+91-7249556789</span>
              </a>{" "}
              and fill online form posted, and remit the subscription fee. On
              the activation of your subscription, our field executives will
              visit your properly and connect you.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withRouter(Footer);
