import React from "react";
import { withRouter } from "react-router-dom";
import "./terms-of-use.css";
import Footer from "../../../components/Footer/footer";
import Navbar from "../../../components/NavBar/index";

class terms_of_use extends React.Component {
  componentDidMount(){
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }
  render() {
    return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="termscontainer">
        <div>
          <p className="terms-Txt">These terms of use <span className="highlight-txt">("Terms") </span>constitute a legally binding agreement between you and Myplotpic Private Limited (the "Company") regarding your use of the website www.myplotpic.com (the "Site") and any services offered by the Company including but not limited to delivery of content via the Site, any mobile or internet connected device or otherwise (the "the Service").</p>
          <p className="terms-Txt">Your use of the Site and services and tools are governed by the following Terms as applicable to the Company including the applicable policies which are incorporated herein by way of reference. By mere use of the Site, You shall be contracting with Myplotpic Private Limited, the owner of the Platform. These terms and conditions including the policies constitute Your binding obligations, with Myplotpic.</p>
          <p className="terms-Txt">When You use any of the services provided by Us through the Platform, including but not limited to, (e.g. Customer Reviews), You will be subject to the rules, guidelines, policies, terms, and conditions applicable to such service, and they shall be deemed to be incorporated into this Terms and shall be considered as part and parcel of this Terms.</p>
        </div>
        <div>
          <p className="terms-Title">I. Defined Terms</p>
          <p className="terms-Txt">Unless otherwise specified, the capitalized words shall have the meanings as defined herein below:</p>
          <ul>
            <li>"Agreement" shall mean and include the completed application form, its attachment(s) and the terms and conditions stated herein. It shall be deemed to have been executed at Visakapatnam AndhraPradesh, India.</li>
            <li>"Company": is defined as Myplotpic Private Limited ("the Company") an existing Company under the Companies Act, 1956 and having its corporate office at D.No 5-1, Survey Number 159, Markavalasa, Madhurawada, Visakapatnam, Andhra Pradesh, India- 530048, along with its unit for the Company's website Myplotpic.com.</li>
            <li>"Date of Commencement" is the date indicating the acceptance of the application by the User to the service. It shall be specified by the Company in its notice to You either through e-mail or conventional mail.</li>
            <li>"Date of Termination" is the date of expiry mentioned in the notice or/and the letter of termination.</li>
            <li>"Myplotpic.com" is defined as the internet website of the Company at www.myplotpic.com</li>
            <li>"Subscriptions" contains time to time information and description of the Services for the User provided by the Company in writing or contained in the website Myplotpic.com.</li>
            <li>"Registration Data" is the database of all the particulars and information supplied by the User on initial application and subscription, including but without limiting to the User's name, telephone number, mailing address, account and email address.</li>
            <li>"User" is defined as an individual or corporate subscriber for the Services and the signatory, whose particulars are contained in the application form and includes his successors and permitted assignees. "User" or "You" also includes any person who access or avail this Site of the Company for the purpose of hosting, publishing, sharing, transacting, displaying or uploading information or views and includes other persons jointly participating in using the Site of the Company.</li>
            <li>Words referring to masculine include the feminine and the singular include the plural and vice versa as the context admits or requires; and Words importing persons includes individuals, bodies corporate and unincorporated.</li>
          </ul>
        </div>
        <div>
          <p className="terms-Title">II. Term</p>
          <p className="terms-Txt">These Terms shall continue to form a valid and binding contract between the Parties, and shall continue to be in full force and effect until the User continues to access and use the Site.</p>
        </div>
        <div>
          <p className="terms-Title">III. Services</p>
          <p className="terms-Txt">Company provides a number of internet-based services through its platform and shall include:</p>
          <ul>
            <li>Virtual property visit of the user property.</li>
            <li>Posting User profile or listing for the purpose of sale/rental of property, and related property services etc.</li>
            <li>Find a property through Myplotpic.com and its internet links.</li>
            <li>Place a print advertisement in any of the group publications through the www.Myplotpic.com site.</li>
            <li>Post advertisements on Myplotpic.com.</li>
            <li>Send advertisements and promotional messages through emails and messages.</li>
          </ul>
          <p className="terms-Txt">The Services can be purchased through various methods of payments offered. The purchase of Services shall be additionally governed by specific policies of sale, like subscription fees, payment and Refund policy, cancellation policy etc.</p>
        </div>
        <div>
          <p className="terms-Title">IV. Eligibility</p>
          <p className="terms-Txt">You hereby represent and warrant to the Company that you are at least eighteen (18) years of age or above and are capable of entering, performing and adhering to these Terms. While individuals under the age of 18 may utilize the Service of the site, they shall do so only with the involvement & guidance of their parents and / or legal guardians, under such Parent /Legal guardian's registered account. You agree to register prior to uploading any content and / or comment and any other use or services of this site and provide your details including but not limited to complete name, age, email address, residential address, and contact number.</p>
        </div>
        <div>
          <p className="terms-Title">V. Subscription Fees</p>
          <ul>
            <li>The applicable rate of the Subscription Fees for the Service provided shall be such as mentioned in the ‘Virtual Site Visit’ Order page or as may be prescribed by the Company from time to time.</li>
            <li>Liability for the Subscription Fees shall accrue from the Date of Order.</li>
          </ul>
        </div>
      </div>
      <Footer/>
    </div>
    
    );
  }
}

export default withRouter(terms_of_use);
